@font-face {
  font-family: logoFont;
  src: url("logo.ttf");
}

.App {
  display: grid;
  grid-template-columns: 1fr 1200px 1fr;/* Adjust the number of columns */
  grid-template-rows: 1fr 350px 1fr;/* Adjust the number of rows */
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  gap: 5px;
  grid-template-areas:
   "sidebar   sources   home"
   "sidebar   main      ."
   "sidebar-exit    footer    footer";
  /*align-items: center;
  text-align: center;
  max-width: 100vw;
  max-height: 100vh;
  overflow: visible;*/
  /* padding: 20px; */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*originally 1405*/
@media (max-width: 1599.98px) {
  .App-logo {
    
    padding: 0 10px; /* Add padding on smaller screens */
  }
}
/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  /*background:rgb(0, 0, 12);*/
  background: linear-gradient(180deg, rgb(0, 0, 12),rgb(6, 4, 32));
  overflow: hidden;
  
}

::-webkit-scrollbar {
  z-index: 30;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #2b2e33;
}
::-webkit-scrollbar-track {
  background-color: #181a1d;
}
.side-bar-button {
  transition: transform 0.3s ease;
  grid-area: sidebar;
  margin-top: 17px;
  margin-left: 7px;
  width: 23px;
  height: 23px;
  
}
.side-bar-button:hover {
  transform: scale(1.15);
  cursor: pointer;
}
.side-bar {
  overflow: hidden;
  grid-area: sidebar;
  /*background: linear-gradient(270deg, rgb(22, 22, 54),rgb(6, 4, 32));*/
  background-color: rgb(0, 0, 20);
  opacity: 1; 
  width: calc(90%);
  height: calc(100%);
  z-index: 1000;
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-template-areas: 
  "sidebar-button"
  "SideBar-Login"
  "SideBar-Home"
  "SideBar-Register"
  "."
  "."
  "."
  ".";
  border: solid;
  /*border-top-right-radius: 15px;*/
  border-right-color:white ;
  border-bottom: transparent;
  
  
}

.SideBar-Login-Container {
  grid-area: SideBar-Login;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: logoFont;
  opacity: 1; 
}


.SideBar-Login-Button {
  grid-area: SideBar-Login;
  color: white;
  opacity: 1; 
  z-index: 1000;
  background-color: transparent;
  font-size: 25px;
  align-content: center;
  text-align: center;
}

.SideBar-Login-Button:hover {
  transform: scale(1.15);
  text-shadow: 0px 0px 12px 6px white;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  
}

.SideBar-Home-Container {
  grid-area: SideBar-Home;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: logoFont;
  z-index: 1000;
  opacity: 1; 

}

.SideBar-Home-Button {
  grid-area: SideBar-Home;
  color: white;
  opacity: 1; 
  background-color: transparent;
  font-size: 25px;
}

.SideBar-Home-Button:hover {
  transform: scale(1.15);
  text-shadow: 0px 0px 12px 6px white;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  
}





.overlay {
  z-index: 4000;
  width: 100vw!important;
  height: 100vh!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: #2b2e33;
  opacity: 0.97;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Popup {
  opacity: 1; 
  z-index: 5000;
  /*grid-area: main;*/
  /*justify-content: center;
  align-items: center;*/
  width: 440px;
  height: 380px;
  background: white;
  border: solid;
  border-radius: 4px;
  border-color: transparent;
  display: grid;
  grid-template-columns: none;
  grid-template-rows: 0.3fr 3fr;
  grid-template-areas: 
  "cancel"
  "login";
  
  
  
  
  
  
}

.login-container{
  grid-area: login;
  /*z-index: 15;*/
  width: calc(100%);
  height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 1; 
}

.input-login {
  width: calc(85%);
  height: calc(10%);
  border: solid;
  border-radius: 4px;
  border-color: transparent;
  background-color: black;
  color: white;
  
}

.login-button {
  width: calc(85%);
  height: calc(13%);
  border: solid;
  border-radius: 35px;
  border-color: transparent;
  background-color: black;
  color: white;
  font-family: logoFont;
  font-size: 17px;
}

.close-container {
  grid-area: cancel;
  opacity: 1; 
  height: 10px;
  position: relative;
  left: 400px;
  top: 8px;
  
  
  
  
}

.close {
  width: 30px;
  height: 25px;
  
  
}

.close:hover {
  transform: scale(1.15);
}






.side-bar-exit {
  grid-area: sidebar-exit;
  /*background: linear-gradient(270deg, rgb(22, 22, 54),rgb(6, 4, 32));*/
  background-color: rgb(0, 0, 20);
  opacity: 1;  
  width: calc(90%);
  height: calc(100%);
  z-index: 1000;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
  ". . . ."
  ". . . exit-button";
  border:solid;
  border-bottom-right-radius: 15px;
  border-right-color: white;
  border-top: transparent;
  
  
  
  

}

.sidebar-exit-image {
  width: 30px;
  height: 30px;
  opacity: calc(100%);
  grid-area: exit-button;
}

.sidebar-exit-image:hover {
  transform: scale(1.15);
}

.side-of-search {
  height: 50px;
  width: 65px;
  background-color: #2c2f33;
  grid-area: main;
  border: solid;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  position:relative;
  z-index: 10;
  left: 15px;
  border-color: transparent;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  
  

}
.search-bar:hover ~ .side-of-search{
  outline: none;
  /*border-color: white;*/
  box-shadow: 0px 0px 12px 6px white; /* 0 0 12 */
}
/*1399.98 / 1850 */  
@media only screen and (max-width: 2000px) {
  .search-wrap {
    height: 50px;
    width: calc(100%);
    background-color: black;/* #2c2f33;*/
    border: solid;
    border-color: transparent;
    border-radius: 100px;
    grid-area: main;
    display: grid;
    grid-template-columns: 6fr 1fr;/* Adjust the number of columns */
    overflow: hidden;
    gap: 1px;
    grid-template-areas:
    "input   button";
    outline: solid;
    outline-color: rgb(223, 223, 223);
    outline-width: 1px;
    transition: transform 730ms ease;
    
    

  }
  .search-wrap:hover {
    box-shadow: 0px 0px 12px 6px white; 
  }

  .search-wrap:focus-within {
    box-shadow: 0px 0px 12px 6px white; 
  }

  
  .search-bar-container {
    grid-area: main;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 730ms ease;
    
  }


  .search-bar {
    position: relative;
    z-index: 10;
    background-color: black;/*#2c2f33;*/
    color: white;
    border: solid;
    border-color: transparent;
    /*border-radius: 100px;*/
    /*border-color: transparent;*/
    width: calc(105%); /* Adjusted to account for the button width + some padding original=820px production version=520px */
    /*width:1200px;*/
    height: 50px;
    font-size: 18px;
    box-sizing: border-box;
    /*margin-top:300px; original=300px */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    /*grid-area: 5 / 2 / 5 / 9;*/ /* row-start / column-start / row-end / column-end */
    /*border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;*/
    /*outline: solid;
    outline-color: red;*/
    left: 17px;
    /*grid-area: input;*/
    
    
    
    

  
    
    
    
    
  }
  .search-bar:hover {
    /*outline: none;*/
    /*border-color: white;*/
    /*box-shadow: 0px 0px 12px 6px white; /* 0 0 12 */
    
    
    
  }

  .search-bar:focus {
    /*border-color:transparent;*/
    /*box-shadow: 0px 0px 12px 6px white;*/ /*  0 0 12 */
    outline:none;
    
  }

  .button-container { /* the button container is positioned in the correct grid box and then the actual element is positioned within the grid by using the position property with a value of absolute */
    height: 100%; /*50px*/
    width: 100%; /*80px*/
    /*grid-area: 5 / 9 / 5 / 9;*/ /* row-start / column-start / row-end / column-end */
    grid-area: main;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: -10px;
    

  }
  .search-button {
    position:relative;
    z-index: 10;
    /*position: absolute;
    top: 20px;*/
    /*right: 205px;*/ /* Adjusted to move the button slightly to the left original=422px */
    /*top: 448px; */ /*orginal=420px */
    border: solid;
    border-radius: 100px;
    border-color: transparent;
    width: 60px;
    height: 35px;
    font-size: large;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    background-color: transparent;
    color: white;
    display: flex;
    align-self: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    /*visibility: hidden;*/
    left: 100px;
    grid-area: button;
    
    
    
  }



  .search-button:hover {
    /*position: absolute;
    right: 205px;*/ /* Adjusted to move the button slightly to the left original=422px */
    /*top:448px;
    border: solid;
    border-radius: 100px;
    border-color: transparent;
    width: 60px;
    height: 35px;
    font-size: large;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    background-color: black;
    color: white;*/
    /*box-shadow: 1px 1px 20px white;*/
    cursor: pointer;
    
  }

  .output-display {
    color: white;
    background-color:#312e2e;
    border: solid;
    border-radius: 5px;
    border-color:#312e2e;
    height:70px;
    width:880px;
    margin-left: 400px;
    

  }

  .searching-display {
    color:white;
    
  }

  .clear-button {
    border: solid;
    border-radius: 100px;
    border-color: white;
    width: 100px;
    height: 40px;
    font-size: large;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

  }

  h1 {
    color:white;
    font-family: logoFont;
    /*margin-left: 1%;
    margin-right: 89%;*/
    font-size: 25px;
    grid-area: sidebar;
    margin-left: 40px;
    
    
  }

  h1:hover {
    cursor: pointer;
  }


  .test-output {
    background-color: rgb(44, 47, 51, 0.4);
    color:white;
    white-space: pre-wrap; /* preserves white spaces and line breaks */
    word-wrap: break-word; /* ensures long words do not cause horizontal scrolling */
    max-width: 100%; /* ensure it does not exceed its container */
    overflow-x: hidden; /* hides horizontal scrollbar */
    overflow-y: hidden;
    /*margin-left:450px;
    margin-top: 100px;*/
    max-height:fit-content;
    font-size: 18px;
    border:solid;
    border-radius: 5px;
    border-color: transparent;
    /*height:fit-content;*/
    padding: 20px;
    grid-area: main;
    text-align: center;
    

    
    
    
    
    
    
  }

  .home-button {
    background-color:transparent;
    /*margin-top: -120px;
    margin-right: 40px;
    margin-left: 1460px;*/
    transition: transform 0.3s ease;
    grid-area: home;
    margin-top: 25px;
    margin-right: 10px;
    
    
    
    
  }

  .home-button:hover {
    background-color: transparent;
    transform: scale(1.3);
    grid-area: home;
    
  }

  .source-container {
    width: calc(100%);
    height: calc(100%);
    grid-area: sources;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    grid-template-areas: 
    "alpha1 alpha2"
    "alpha3 alpha4";
    margin-top: 90px;
  }

  .source-one {
    color:white;
    background-color:  rgb(44, 47, 51, 0.4);
    /*position:absolute;*/
    /*top:0px;
    left: 680px;*/
    border:solid;
    border-radius: 5px;
    border-color:transparent;
    width:fit-content;
    height: fit-content;
    text-align: center;
    flex-direction: column !important; /* Arrange items in a column */
    white-space: normal !important;  /* Override the default whitespace behavior of pre */
    padding:10px;
    font-size: 15px;
    grid-area: alpha4;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    
    
    
    
    

  }

  .source-one:hover {
    color:black;
    background-color: rgba(255, 255, 255, 0.875);
    border:solid;
    border-radius: 5px;
    border-color:transparent;
    width: fit-content;
    height: fit-content;
    box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.875);
    display: flex !important;
    flex-direction: column !important; /* Arrange items in a column */
    align-items: center !important; /* Center items horizontally in the flex container */
    justify-content: center !important; /* Center items vertically in the flex container */
    white-space: normal !important; /* Override the default whitespace behavior of pre */
    padding:15px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;

    
    
  }

  img {
    height: 30px;
    width: 30px;
    border: solid;
    border-color: transparent;
    border-radius: 100px;
    margin:auto;
    
  }

  .button-image {
    transform: rotate(-90deg);
    height:40px; /*30*/ /*50*/
    width:60px; /*40*/ /*60*/
    margin-top: -8px;
    
    
    
    
  }
  
  
  

  .textarea-test {
    background-color: #282c34;
    color:white;
    margin-top:200px;
    border:solid;
    border-radius: 100px;
    border-color:rgb(0, 247, 255);
    width:700px;
    height: 40px;
    text-align: left;
    font-size: 30px;
    resize: none;
    visibility: hidden;
  }
}
/* Setting up the media query for phones with a max-wdith of 1265px; */
/*originally 1265*/ /*1399.98*/
@media only screen and (max-width: 1483px) {
 .App {
  grid-template-columns: 1fr 1000px 1fr;
 }

 

 .search-button {
  left: 75px;
 }

 .search-bar {
  /*outline: solid;
  outline-color: red;
  width: calc(85%);*/

 }
 

  
  
}

/* Setting up the media query for phones with a max-wdith of 905px; */

@media only screen and (max-width: 1295px) {
  .App {
    grid-template-columns: 1fr 800px 1fr;
  }

  .search-wrap {
    grid-template-columns: 4.5fr 1fr;
  }

  
  
  
  
  
}

/* Setting up the media query for phones with a max-wdith of 665px; */

@media only screen and (max-width: 1083px) {
  .App {
    grid-template-columns: 1fr 600px 1fr;
  }

  .search-wrap {
    grid-template-columns: 3fr 1fr;
  }
  
  
  
  
}

/* Setting up the media query for phones with a max-wdith of 575px; */

@media only screen and (max-width: 884px) {
  h1 {
    font-size: 15px;
  }


  


  
  
}

@media only screen and (max-width: 783px) {
  .App {
    grid-template-columns: 1fr 500px 1fr;
  }

  .search-wrap {
    grid-template-columns: 2.5fr 1fr;
  }
  .search-bar {
    width: calc(110%);
  }
  
  
  
  
}
@media only screen and (max-width: 682px) {
  h1 {
    font-size: 15px;
    grid-area: sources;
    display: flex;
    justify-content: center;
  }
  .source-one {
    
  }
}

@media only screen and (max-width: 582px) {
  .App {
    grid-template-columns: 1fr 500px 1fr;
  }
  
  
  
  
}

@media only screen and (max-width: 532px) {
  .App {
    grid-template-columns: 1fr 15fr 1fr;
  }

  .search-wrap {
    grid-template-columns: 2.3fr 1fr;
    height: 38px;
  }

  .search-bar {
    /*transform: scale(1.1);*/
    width: calc(115%);
    height: 35px;
    
    
    
    

    
    
  }
  .search-button {
    transform: scale(0.8);
    

  }
  
  .button-container {
    margin-left: -40px;
  }

  h1 {
    font-size: 20px;
    grid-area: sources;
    display: flex;
    justify-content: center;
    
  }
  
  
  
}

@media only screen and (max-width: 514px) {
  .App {
    grid-template-columns: 1fr 20fr 1fr;
  }
  .search-wrap {
    grid-template-columns: 2.1fr 1fr;
    transform: scale(0.9);
    width: calc(100%);
  }
  .search-bar {
    height: 40px;
    
  }

  .search-button {
    transform: scale(0.9);
  }

}

@media only screen and (max-width: 480px) {
  .search-wrap{
    grid-template-columns: 2fr 1fr;
  }
}

@media only screen and (max-width: 465px) {
  .search-wrap{
    grid-template-columns: 5.6fr 2fr 1fr;
  }
  
}

@media only screen and (max-width: 440px) {
  .search-wrap{
    grid-template-columns: 5.4fr 2fr 1fr;
  }
 
}

@media only screen and (max-width: 430px) {
  .search-wrap{
    grid-template-columns: 5.1fr 2fr 1fr;
  }
 
}

@media only screen and (max-width: 415px) {
  .search-wrap{
    grid-template-columns: 4.9fr 2fr 1fr;
  }
 
}


  
 

@media only screen and (max-width: 375px) {
  .search-wrap {
    grid-template-columns: 1fr 0.75fr;
    grid-template-rows: 1fr 3fr;
  }

  .search-bar {
    width: calc(130%);
  }
  .search-bar-container {
    margin-top: 85px;
  }

  .source-one {
    transform: scale(0.8);
    width: 150px;
    align-self: center;
  }
  .test-output {
    transform: scale(0.8);
    font-size: 10px;
  }
  .home-button {
    transform: scale(0.8);
    margin-top: 5px;
  }
  


}

@media only screen and (max-width: 288px) {
  .search-wrap {
    transform: scale(0.8);
    width: 290px;
    grid-template-columns: 1fr 0.9fr;
  }
}




